import React, { useEffect, useState } from 'react'
import { Trans, useTranslation }      from 'react-i18next'
import {
  Autocomplete,
  Box,
  Button, Checkbox, Chip,
  Container, FormControl,
  FormControlLabel, FormGroup,
  Grid, IconButton, Input, InputAdornment, InputLabel, Link, MenuItem,
  Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
}                                 from '@mui/material'
import { useApi }                 from '../utils/api'
import { useNavigate, useParams }    from 'react-router-dom'
import { useSnackbar }                 from 'notistack'
import ShortFormControl                from '../ui/components/short-form-control'
import AlertSimpleDialog               from '../ui/components/alert-simple-dialog'
import { BaseBreadcrumbs }             from '../ui/components/BaseBreadcrumbs'
import Page                            from '../ui/components/Page'
import useCities                       from '../utils/useCities'
import Iconify                         from '../ui/components/Iconify'
import BaseTable                       from '../ui/components/base-table'
import { fDateTime }                   from '../utils/textutils'
import CityServiceChangeDialog         from './CityServiceChangeDialog'
import CityServiceChangeDeleteDialog   from './CityServiceChangeDeleteDialog'
import CityServiceChangeScheduleDialog from './CityServiceChangeScheduleDialog'

const dayOffList = ['01.01.', '06.01.', '01.05.', '08.05.', '05.07.', '29.08.', '15.11.', '28.10.', '01.11.', '17.11.', '24.12.', '25.12.', '26.12.']

export default function CityServicePage () {

  const api = useApi()
  const navigate = useNavigate()
  const { cityServiceId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [cities] = useCities()
  const [values, setValues] = useState({
    cityId: '',
    name: '',
    phone: '',
    pattern: '',
    active: false,
    availability: [],
    prices: [],
    holidays: [],
    unavailableDays: [],
    ticketDelay: 0,
    smsTicket: '',
    smsNotInService: '',
    smsDelayedProcessing: '',
    smsHelp: '',
    helpSMSPrice: 0,
    flags: 0,
    ticketVariants: []
  })
  const [availability, setAvailability] = useState([])
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [forceReloadChangesCounter, setForceReloadChangesCounter] = useState(0)

  useEffect(() => {
    (async () => {
      if (!isNaN(cityServiceId)) {
        let city = await api.cityService.detail(cityServiceId).catch(() => ({}))
        setValues({
          ...city,
          ticketVariants: city.ticketVariants.map(tv => ({
            ...tv,
            durationWeek: (tv.durationWeek ?? 0) / 60,
            durationWeekend: (tv.durationWeekend ?? 0) / 60,
            durationHoliday: (tv.durationHoliday ?? 0) / 60,
          })),
          flags: 0,
        })
        setAvailability(() => city.availability)
      }
    })()
  }, [cityServiceId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.cityService.delete(cityServiceId).then(() => {
        enqueueSnackbar(t('City service deleted'), {
          variant: 'success'
        })
        navigate('/city-services')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete city service'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
      availability: availability,
    }
    try {
      let cityService = await api.cityService.save(data)
      navigate(`/city-services/${cityService.id}`)
      enqueueSnackbar(t('City service saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save city service'), {
        variant: 'error'
      })
    }
  }

  const prepareFormData = () => {
    return {
      ...values,
      model: 'cityService',
      availability: availability,
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value })
  }

  return (
    <Page title={t('City service')}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumb-city'}
                         title={isNaN(cityServiceId) ? t('Create new city service') : (`${t('City service')} - ${values.name}`)}
                         items={[
                           { href: `/cities`, title: t('Cities') },
                         ]}/>
        <Grid component={'form'} onSubmit={onSubmit} item mb={4} xs={12}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid container sx={{ width: '100%' }} spacing={2}>
              <Grid item xs={12} md={6}>
                <ShortFormControl
                  name={'name'}
                  title={t('Title')}
                  value={values.name}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel id="label-city">{t('City')}</InputLabel>
                  <Select
                    labelId="label-city"
                    id="select-city"
                    value={values.cityId}
                    onChange={(e) => {
                      handleChangeDirect('cityId', e.target.value)
                    }}
                    label="Age"
                  >
                    {(cities ?? []).map(city => (<MenuItem key={`city-${city.id}`} value={city.id}>{city.name}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <ShortFormControl
                  name={'phone'}
                  title={t('Phone number')}
                  value={values.phone}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={8}>
                <ShortFormControl
                  name={'pattern'}
                  title={t('Pattern')}
                  value={values.pattern}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <ShortFormControl
                  name={'ticketDelay'}
                  inputProps={{
                    type: 'number',
                  }}
                  endAdornment={<InputAdornment position="end">{t('sec')}</InputAdornment>}
                  title={t('Send ticket delay')}
                  value={values.ticketDelay}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel label={t('Active')} control={<Checkbox />} checked={values.active} onChange={(e) => {
                    handleChangeDirect('active', e.target.checked)
                  }}/>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <ServiceAvailability t={t}
                                     serviceAvailability={availability}
                                     setServiceAvailability={setAvailability} />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="holidays"
                  options={dayOffList}
                  value={values.holidays ?? []}
                  onChange={(e, newValue) => {
                    handleChangeDirect('holidays', newValue.filter(it => /(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[1,2])\./.test(it)))
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('Holidays')}
                      helperText={t('During this days is applied price defined for holidays')}
                    />
                  )}
                />
                <Link href={'#'} variant={'body2'} onClick={(e) => {
                  e.preventDefault()
                  let selectedDays = values.holidays ?? []
                  dayOffList.forEach((it) => {
                    if (selectedDays.indexOf(it) === -1) {
                      selectedDays.push(it)
                    }
                  })
                  handleChangeDirect('holidays', selectedDays)
                }}>{t('Add all national days off')}</Link>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  multiple
                  id="day-off"
                  options={dayOffList}
                  value={values.unavailableDays ?? []}
                  onChange={(e, newValue) => {
                    handleChangeDirect('unavailableDays', newValue.filter(it => /(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[1,2])\./.test(it)))
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('Unavailable days')}
                      helperText={t('Service is not available during this days')}
                    />
                  )}
                />
                <Link href={'#'} variant={'body2'} onClick={(e) => {
                  e.preventDefault()
                  let selectedDays = values.unavailableDays ?? []
                  dayOffList.forEach((it) => {
                    if (selectedDays.indexOf(it) === -1) {
                      selectedDays.push(it)
                    }
                  })
                  handleChangeDirect('unavailableDays', selectedDays)
                }}>{t('Add all national days off')}</Link>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Typography variant={'h6'}>{t('SMS text setup')}</Typography>
              </Grid>
              {['smsTicket', 'smsNotInService', 'smsDelayedProcessing', 'smsHelp'].map(key => <Grid key={key} item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id={`sms-${key}`}
                    label={t(`label-sms-${key}`)}
                    multiline
                    maxRows={4}
                    variant="standard"
                    value={values[key]}
                    onChange={handleChange(key)}
                    sx={{ width: '100%' }}/>
                </FormControl>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'caption'}>
                    <Trans i18nKey={'sms-text-length'} values={{ length: values[key]?.length ?? 0 }} />
                  </Typography>
                  <Typography variant={'caption'}>{t(`helper-${key}`)}</Typography>
                </Stack>
              </Grid>)}
              <Grid item xs={12} mt={3}>
                <Typography variant={'h6'}>{t('Other setup')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ShortFormControl
                  name={'helpSMSPrice'}
                  inputProps={{
                    type: 'number',
                  }}
                  endAdornment={<InputAdornment position="end">{t('€-cent')}</InputAdornment>}
                  title={t('label-sms-helpSMSPrice')}
                  value={values.helpSMSPrice}
                  handleChange={handleChange}/>
              </Grid>
            </Grid>
          </Paper>
          <Stack direction={'row'} sx={{ mt: 2, px: 1 }}>
            <Button type={'submit'} variant={'contained'}>{t('Save city service')}</Button>
            {!isNaN(cityServiceId) && <>
              <CityServiceChangeScheduleDialog cityServiceId={parseInt(cityServiceId)} getFormData={() => prepareFormData()} onResult={() => {
                setForceReloadChangesCounter((prev) => prev + 1)
              }} />
              <Box sx={{ flex: '1' }} />
              <Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }}
                      onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>
            </>}
          </Stack>
        </Grid>
        {!isNaN(cityServiceId) && !!values.id && <TicketVariants
              t={t}
              cityServiceId={parseInt(cityServiceId)}
              ticketVariants={values.ticketVariants}
              setTicketVariants={(val) => {
                handleChangeDirect('ticketVariants', val)
              }}
              setForceReloadChangesCounter={setForceReloadChangesCounter}
        />}
        {!isNaN(cityServiceId) && <Grid item mt={4} xs={12}>
          <CityServiceChanges
            t={t}
            cityServiceId={cityServiceId}
            forceReloadChangesCounter={forceReloadChangesCounter}
            setForceReloadChangesCounter={setForceReloadChangesCounter}
          />
        </Grid>}
        <AlertSimpleDialog
          title={t('Delete city service')}
          message={t('delete-confirm-msg', { name: values.name })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
    </Page>
  )
}

function TicketVariants({ t, cityServiceId, ticketVariants, setTicketVariants, setForceReloadChangesCounter }) {

  const api = useApi()
  const [internalTicketVariants, setInternalTicketVariants] = useState(ticketVariants)
  const { enqueueSnackbar } = useSnackbar()

  const sanitizeTicketVariantReq = (index) => {
    const ticketVariant = internalTicketVariants[index]
    return {
      ...ticketVariant,
      durationWeek: (ticketVariant.durationWeek ?? 60 * 1) * 60,
      durationWeekend: (ticketVariant.durationWeekend ?? 60 * 1) * 60,
      durationHoliday: (ticketVariant.durationHoliday ?? 60 * 1) * 60,
      cityServiceId: cityServiceId,
      flags: index === 0 ? 1 : 0,
      pattern: index === 0 ? null : ticketVariant?.pattern,
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let newInternalTicketVariants = []
    const internalTicketVariantIds = internalTicketVariants.map(it => it.id).filter(it => !!it)
    const ticketVariantsToDelete = ticketVariants.map(it => it.id).filter(it => internalTicketVariantIds.indexOf(it) === -1)
    const total = ticketVariantsToDelete.length + internalTicketVariants.length
    let success = 0
    for (let i = 0; i < ticketVariantsToDelete.length; i++) {
      try {
        await api.ticketVariant.delete(ticketVariantsToDelete[i])
        success++
      } catch (e) {
        console.error(e)
      }
    }
    for (let i = 0; i < internalTicketVariants.length; i++) {
      try {
        let request = sanitizeTicketVariantReq(i)
        const tv = await api.ticketVariant.save(request)
        success++
        newInternalTicketVariants.push({
          ...tv,
          durationWeek: (tv.durationWeek ?? 0) / 60,
          durationWeekend: (tv.durationWeekend ?? 0) / 60,
          durationHoliday: (tv.durationHoliday ?? 0) / 60,
        })
      } catch (e) {
        console.error(e)
        newInternalTicketVariants.push(internalTicketVariants[i])
      }
    }
    setInternalTicketVariants(() => newInternalTicketVariants)
    setTicketVariants(newInternalTicketVariants)
    const message = total === success ? t('Ticket variants saved successfully') : t('Failed to save ticket variants')
    const variant = total === success ? 'success' : 'error'
    enqueueSnackbar(message, { variant: variant })
  }

  const prepareFormData = () => {
    const internalTicketVariantIds = internalTicketVariants.map(it => it.id).filter(it => !!it)
    const ticketVariantIdsToDelete = ticketVariants.map(it => it.id).filter(it => internalTicketVariantIds.indexOf(it) === -1)

    return {
      model: 'ticketVariants',
      deletedIds: ticketVariantIdsToDelete,
      items: internalTicketVariants.map((it, index) => sanitizeTicketVariantReq(index))
    }
  }

  const handleChangeDirect = (index, prop) => (e) => {
    setInternalTicketVariants((prev) => {
      let result = [...prev]
      result[index][prop] = e.target.value
      return result
    })
  }

  return <Grid item component={'form'} onSubmit={onSubmit}>
    <Paper sx={{ p: 2, width: '100%' }}>
      <Grid container sx={{ width: '100%' }} spacing={2}>
        <Grid item xs={12} mt={2}>
          <Typography variant={'h6'}>{t('Ticket variants & prices')}</Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {internalTicketVariants.map((row, ind) => (
                  <React.Fragment key={`ticket-variant-${ind}`}>
                    <TableRow key={`ticket-type-${ind}`}>
                    <TableCell component="th" scope="row">
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor={`control-${ind}-title`}>{t('Title')}</InputLabel>
                        <Input
                          id={`control-${ind}-title`}
                          value={row.name}
                          onChange={handleChangeDirect(ind, 'name')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor={`control-${ind}-pattern`}>{t('Pattern')}</InputLabel>
                        <Input
                          id={`control-${ind}-pattern`}
                          disabled={ind === 0}
                          value={ind === 0 ? '^.*$' : row.pattern}
                          type={'text'}
                          onChange={handleChangeDirect(ind, 'pattern')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor={`control-${ind}-price`}>{t('Price')}</InputLabel>
                        <Input
                          id={`control-${ind}-price`}
                          endAdornment={<InputAdornment position="end">€-cent</InputAdornment>}
                          value={row.price}
                          type={'number'}
                          onChange={handleChangeDirect(ind, 'price')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      {ind !== 0 && <IconButton aria-label="delete"
                                                color={'error'}
                                                onClick={(e)=>{
                        setInternalTicketVariants((prev)=>{
                          let result = [...prev]
                          result.splice(ind, 1)
                          return result
                        })
                      }}>
                        <Iconify icon={'material-symbols:delete'} />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                    <TableRow key={`ticket-type-durations-${ind}`}>
                      <TableCell colSpan={2}>
                        <Typography variant={'subtitle2'} gutterBottom component="div" sx={{ ml: 2, mt: 1 }}>{t('Ticket duration')}</Typography>
                        <Table size="small">
                          <TableBody>
                            {['week', 'weekend', 'holiday'].map(key => {
                              const keyUCFirst = key.charAt(0).toUpperCase() + key.slice(1)
                              return <TableRow key={`duration-${ind}-${key}`}>
                                <TableCell sx={{ minWidth: '150' }} />
                                <TableCell>{t(`cityServiceAvailability${keyUCFirst}`)}</TableCell>
                                <TableCell>
                                  <Input
                                    id={`field-duration`}
                                    name={`duration${keyUCFirst}`}
                                    endAdornment={<InputAdornment position="end">{t('minutes')}</InputAdornment>}
                                    value={(row[`duration${keyUCFirst}`] ?? 0)}
                                    onChange={handleChangeDirect(ind, `duration${keyUCFirst}`)}
                                    type={'number'}
                                    sx={{ width: '100%' }}
                                  />
                                </TableCell>
                              </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} />
                    </TableRow>
                  </React.Fragment>
                ))}
                <TableRow>
                  <TableCell colSpan={4} align={'center'}>
                    <Stack direction={'row'} justifyContent={'end'}>
                      <Button variant={'outlined'} onClick={(e) => {
                        setInternalTicketVariants((prev) => [...prev, { name: internalTicketVariants.length === 0 ? t('Default ticket variant') : '', pattern: '', price: 0, durationWeek: 60, durationWeekend: 60, durationHoliday: 60 }])
                      }}>
                        {t('Define new ticket variant')}
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
    <Stack direction={'row'} sx={{ mt: 2, px: 1 }}>
      <Button variant={'contained'} type={'submit'}>
        {t('Save ticket variants')}
      </Button>
      <CityServiceChangeScheduleDialog cityServiceId={cityServiceId} getFormData={() => prepareFormData()} onResult={() => {
        setForceReloadChangesCounter((prev) => prev + 1)
      }} />
    </Stack>
  </Grid>
}


function ServiceAvailability({ t, serviceAvailability, setServiceAvailability }) {

  const defaultObject = { from: '', to: '', day: '' }

  const handleChangeDirect = (index, key, value) => [
    setServiceAvailability((prev) => {
      let result = [...prev]
      result[index][key] = value
      return result
    })
  ]

  return <>
        <Grid item xs={12} mt={2}>
          <Typography variant={'h6'}>{t('Service availability')}</Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Day')}</TableCell>
                  <TableCell>{t('Available from')}</TableCell>
                  <TableCell>{t('Available to')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceAvailability.map((row, index) => (
                  <TableRow key={`ticket-type-${index}`}>
                    <TableCell scope="row">
                      <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
                      <Select
                        labelId="label-city"
                        id="select-city"
                        value={row.day}
                        onChange={(e) => {
                          handleChangeDirect(index, 'day', e.target.value)
                        }}
                        label={t('Availability day')}
                      >
                        <MenuItem key={`city-service-week`} value={'week'}>{t('cityServiceAvailabilityWeek')}</MenuItem>
                        <MenuItem key={`city-service-weekend`} value={'weekend'}>{t('cityServiceAvailabilityWeekend')}</MenuItem>
                        <MenuItem key={`city-service-holiday`} value={'holiday'}>{t('cityServiceAvailabilityHoliday')}</MenuItem>
                      </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell scope="row">
                      <TextField id={`field-from-${index}`} variant="standard"
                                 value={row.from}
                                 type={'time'}
                                 onChange={(e)=> handleChangeDirect(index, 'from', e.target.value)}
                                 sx={{ width: '100%' }}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField id={`field-to-${index}`} variant="standard"
                                 value={row.to}
                                 type={'time'}
                                 onChange={(e)=> handleChangeDirect(index, 'to', e.target.value)}
                                 sx={{ width: '100%' }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="add"
                                  color={'primary'}
                                  onClick={(e)=>{
                                    setServiceAvailability((prev)=> prev.toSpliced(index, 0, { ...defaultObject }))
                                  }}>
                        <Iconify icon={'material-symbols:add'} />
                      </IconButton>
                      <IconButton aria-label="delete"
                                                color={'error'}
                                                onClick={(e)=>{
                                                  setServiceAvailability((prev)=>{
                                                    let result = [...prev]
                                                    result.splice(index, 1)
                                                    return result
                                                  })
                                                }}>
                        <Iconify icon={'material-symbols:delete'} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell>
                    <IconButton aria-label="add"
                                color={'primary'}
                                onClick={(e)=>{
                                  setServiceAvailability((prev)=> [...prev, { ...defaultObject }])
                                }}>
                      <Iconify icon={'material-symbols:add'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
}

const CityServiceChanges = ({ cityServiceId, setForceReloadChangesCounter, forceReloadChangesCounter, t }) => {

  const api = useApi()

  const changesColumns = [
    {
      field: 'model', headerName: t('Type of scheduled change'), flex: 1, sortable: false, filterable: false,
      renderCell: (cell) => {
        return t(`model-${cell.row.model}`)
      }
    },
    {
      field: 'scheduled', headerName: t('Scheduled'), flex: 1, sortable: false, filterable: false,
      renderCell: (cell) => {
        return cell.row.scheduled ? fDateTime(cell.row.scheduled) : '---'
      }
    },
    {
      field: 'executed', headerName: t('Executed'), flex: 1, sortable: false, filterable: false,
      renderCell: (cell) => {
        return cell.row.executed ? fDateTime(cell.row.executed) : '---'
      }
    },
    { field: 'id', headerName: '#', flex: 1, sortable: false, filterable: false, renderCell: (cell) => {
        return <>
          <CityServiceChangeDialog object={cell.row.originalData} changedObject={cell.row.changedData} />
          {!cell.row.executed && <CityServiceChangeDeleteDialog csChange={cell.row} onResult={(res) => {
            if (res) {
              setForceReloadChangesCounter((prev) => prev + 1)
            }}} />}
        </>
      }},
  ]

  return <React.Fragment>
    <Stack direction="row" alignItems="center" mb={2} spacing={2}>
      <Typography variant="h4" gutterBottom>{t('Scheduled changes')}</Typography>
    </Stack>
    <BaseTable
      columns={changesColumns}
      query={null}
      forceReloadCounter={forceReloadChangesCounter}
      loadCallback={async (query, page, pageSize) => {
        try {
          return await api.cityService.scheduledChangesList(cityServiceId)
        } catch (e) {
          console.log('Failed to load scheduled changes for city service', e)
          return []
        }
      }}
    />
  </React.Fragment>
}